import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./Industry.css";
import GenericImage1 from "../../img/generic/generic-corporate-3-1-full.jpg";
import GenericImage2 from "../../img/generic/generic-corporate-3-2-full.jpg";
import GenericImage3 from "../../img/generic/generic-corporate-3-3-full.jpg";
import BuildBg from "../../img/landing/build_bg.jpg"; // Import the background image
import historyImage1 from "../..//img/history/history-1.jpg";
import historyImage2 from "../../img/history/history-2.jpg";
import historyImage3 from "../../img/history/history-3.jpg";
import parallaxImage from "../../img/parallax/parallax-2.jpg";

const Fmcg = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <section
        className="page-header page-header-modern page-header-lg"
        style={{ backgroundColor: "#e3eff9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 className="text-color-dark font-weight-bold">Agriculture</h1>
            </div>
            <div className="col-md-4 order-1 order-md-2 align-self-center">
              <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
                <li>
                  <a
                    role="button"
                    onClick={() => navigate("/")}
                    className="text-color-default text-color-hover-primary text-decoration-none"
                  >
                    HOME
                  </a>
                </li>
                <li>Industries</li>
                <li className="active">Agriculture</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container pb-1 ">
        <div className="row pt-4">
          <div className="col">
            <div className="overflow-hidden mb-3">
              <h2
                className="word-rotator slide font-weight-bold text-8 mb-0"
                data-aos="maskUp"
              >
                <span>
                  Empowering Agriculture with Accurate GPS and Data Insights{" "}
                </span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-lg-12">
            <div className="overflow-hidden">
              <p className=" mb-0" data-aos="maskUp" data-aos-delay="250">
                In the modern agricultural landscape, precision and efficiency
                are paramount for maximizing yields and minimizing waste.
                Findpath empowers farmers by providing accurate GPS tracking
                solutions that enhance operational visibility and
                decision-making. With our advanced technology, farmers can
                monitor their assets, track field conditions, and optimize
                resource allocation in real-time.
              </p>
              <p className=" mb-0" data-aos="maskUp" data-aos-delay="250">
                Our GPS systems enable precise mapping of fields, allowing for
                better planning and execution of planting, irrigation, and
                harvesting activities. Coupled with robust data analytics,
                Findpath helps farmers gain valuable insights into crop health,
                soil conditions, and weather patterns. This information
                facilitates informed decisions that lead to improved
                productivity and sustainability.
              </p>
              <p className=" mb-0" data-aos="maskUp" data-aos-delay="250">
                By integrating technology into agriculture, Findpath not only
                supports farmers in increasing their output but also promotes
                responsible farming practices. Experience the future of
                agriculture with Findpath, where accurate GPS and data insights
                work together to cultivate a more efficient and profitable
                farming operation.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col py-4">
          <hr class="solid custom-hr" />
        </div>
      </div>
      <div className="container-fluid">
        <div className="row align-items-center bg-color-grey">
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0"
              data-plugin-parallax
              data-plugin-options="{'speed': 3, 'horizontalPosition': '100%'}"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${GenericImage1})`,
              }}
            ></section>
          </div>
          <div className="col-lg-6 p-0">
            <section className="section section-no-border h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-left">
                  <div className="overflow-hidden">
                    <h4
                      className="mb-0"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="100"
                    >
                      <a
                        href="#"
                        className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1"
                      >
                        24/7 Visibility of Equipment
                      </a>
                    </h4>
                  </div>

                  <p
                    className="text-2 mb-0"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                  >
                    Findpath provides 24/7 visibility of agricultural equipment,
                    enabling farmers to monitor their assets in real-time. This
                    continuous tracking ensures optimal usage and timely
                    maintenance, reducing downtime and enhancing operational
                    efficiency. By leveraging advanced GPS technology, farmers
                    can swiftly locate equipment, improve logistics, and make
                    informed decisions, ultimately leading to increased
                    productivity and a more streamlined farming operation.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="row align-items-center bg-color-grey">
          <div className="col-lg-6 order-2 order-lg-1 p-0">
            <section className="section section-no-border h-100 m-0">
              <div className="row justify-content-end m-0">
                <div className="col-half-section col-half-section-right custom-text-align-right">
                  <div className="overflow-hidden">
                    <h4
                      className="mb-0"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="100"
                    >
                      <a
                        href="#"
                        className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1"
                      >
                        Precise Farm Area Calculation
                      </a>
                    </h4>
                  </div>
                  <p
                    className="text-2 mb-0"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                  >
                    Findpath’s precise farm area calculation feature utilizes
                    advanced GPS technology to deliver accurate measurements of
                    agricultural land. This tool allows farmers to assess field
                    sizes efficiently, ensuring optimal resource allocation for
                    planting, irrigation, and harvesting. By eliminating
                    guesswork, farmers can make informed decisions that enhance
                    productivity, reduce waste, and maximize yields, ultimately
                    contributing to more sustainable farming practices.
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 p-0">
            <section
              className="parallax section section-parallax h-100 m-0"
              data-plugin-parallax
              data-plugin-options="{'speed': 1, 'horizontalPosition': '100%'}"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${GenericImage2})`,
              }}
            ></section>
          </div>
        </div>
        <div className="row align-items-center bg-color-grey">
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0"
              data-plugin-parallax
              data-plugin-options="{'speed': 1, 'horizontalPosition': '100%'}"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${GenericImage3})`,
              }}
            ></section>
          </div>
          <div className="col-lg-6 p-0">
            <section className="section section-no-border h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-left">
                  <div className="overflow-hidden">
                    <h4
                      className="mb-0"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="100"
                    >
                      <a
                        href="#"
                        className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1"
                      >
                        Geofence the Forbidden Areas
                      </a>
                    </h4>
                  </div>

                  <p
                    className="text-2 mb-0"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                  >
                    Findpath’s geofencing feature empowers farmers to establish
                    virtual boundaries around sensitive or restricted areas on
                    their farms. By creating geofences, farmers can prevent
                    unauthorized access to specific zones, enhancing security
                    and protecting valuable resources. This technology allows
                    for real-time alerts if equipment or personnel enter these
                    restricted areas, ensuring compliance and safeguarding
                    crops, livestock, and equipment from potential threats.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <section
        className="parallax section section-text-light section-parallax"
        data-plugin-parallax=""
        data-plugin-options={{ speed: "1.5", parallaxHeight: "210%" }}
        style={{ position: "relative", overflow: "hidden" }}
      >
        {/* Parallax background with inline style */}
        <div
          className="parallax-background"
          style={{
            backgroundImage: `url(${parallaxImage})`,
            backgroundSize: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "210%",
            transform: "translate3d(0px, -100.171px, 0px)",
            backgroundPositionX: "50%",
          }}
        ></div>

        <section className="call-to-action">
          <div className="container">
            <div className="row">
              <div className="col-sm-9 col-lg-9">
                <div className="call-to-action-content">
                  <h3>
                  Get our GPS for
                    <strong className="font-weight-extra-bold">
                      {" "}
                      agriculture today
                    </strong>{" "}
                    and optimize your{" "}
                    <strong className="font-weight-extra-bold">
                    farm management !
                    </strong>
                  </h3>
                  <p className="mb-0">Order our GPS now to enhance your agricultural efficiency and productivity.</p>
                </div>
              </div>
              <div className="col-sm-3 col-lg-3">
                <div className="call-to-action-btn">
                  <a
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-modern text-2 btn-primary"
                  >
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <div className="row">
        <div className="col-md-8 mx-md-auto text-center">
          <h2 className="text-color-dark font-weight-normal text-7 mb-0 pt-2">
            Our <strong className="font-weight-extra-bold">What we Offer</strong>
          </h2>
          <p>Get Real-Time Visibility and Control with Findpath GPS Technology</p>

          <section className="timeline" id="timeline">
            <div className="timeline-body">
              <div className="timeline-date">
                <h3 className="text-primary font-weight-bold">2024</h3>
              </div>

              <article
                className="timeline-box left text-start"
                data-aos="fade-in" // AOS animation
                data-aos-delay="200" // Delay for the animation
              >
                <div className="timeline-box-arrow"></div>
                <div className="p-2">
                  <img alt="" className="img-fluid" src={historyImage3} />
                  <h3 className="font-weight-bold text-3 mt-3 mb-1">
                  Real-Time Tracking
                  </h3>
                  <p className="mb-0 text-2">
                  Findpath’s real-time tracking solution provides instant visibility of assets and vehicles, enhancing operational efficiency and decision-making for businesses.
                  </p>
                </div>
              </article>

              <div className="timeline-date">
                <h3 className="text-primary font-weight-bold">2012</h3>
              </div>

              <article
                className="timeline-box right text-start"
                data-aos="fade-in"
                data-aos-delay="400"
              >
                <div className="timeline-box-arrow"></div>
                <div className="p-2">
                  <img alt="" className="img-fluid" src={historyImage2} />
                  <h3 className="font-weight-bold text-3 mt-3 mb-1">
                  Asset Security
                  </h3>
                  <p className="mb-0 text-2">
                  Findpath’s asset security solutions provide real-time tracking and alerts, ensuring the protection of your valuable resources against theft and loss.
                  </p>
                </div>
              </article>

              <div className="timeline-date">
                <h3 className="text-primary font-weight-bold">2006</h3>
              </div>

              <article
                className="timeline-box left text-start"
                data-aos="fade-in"
                data-aos-delay="600"
              >
                <div className="timeline-box-arrow"></div>
                <div className="p-2">
                  <img alt="" className="img-fluid" src={historyImage1} />
                  <h3 className="font-weight-bold text-3 mt-3 mb-1">
                  Theft Prevention
                  </h3>
                  <p className="mb-0 text-2">
                  Findpath’s theft prevention system offers real-time monitoring and alerts, safeguarding your assets from unauthorized access and potential theft effectively.
                  </p>
                </div>
              </article>
            </div>
          </section>
        </div>
      </div>
      <section
        className="section section-dark section-angled border-0 lazyload pb-0 m-0"
        style={{
          backgroundSize: "100%",
          backgroundPosition: "top",
          backgroundImage: `url(${BuildBg})`,
        }}
      >
        <div
          className="section-angled-layer-top section-angled-layer-increase-angle bg-color-grey"
          style={{ padding: "4rem 0" }}
        ></div>
        <div className="container text-center my-5 py-5">
          <h2
            className="font-weight-bold line-height-3 text-12 mt-5 mb-3 appear-animation"
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="250"
            data-appear-animation-duration="750"
            data-aos="fade-up" // Add AOS animation
            data-aos-duration="1000" // Duration of the animation
            data-aos-delay="100" // Delay before the animation starts
          >
            Request a demo
          </h2>
          {/* <h4
            className="font-weight-bold text-9 mb-4 pb-2 appear-animation"
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="500"
            data-appear-animation-duration="750"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            Purchase now. Only{" "}
            <span
              className="highlighted-word highlighted-word-animation-1 highlighted-word-animation-1-no-rotate highlighted-word-animation-1 highlighted-word-animation-1-light alternative-font-4 font-weight-extra-bold text-4 light appear-animation"
              data-appear-animation="blurIn"
              data-appear-animation-delay="800"
              data-appear-animation-duration="750"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              $16!
            </span>
          </h4> */}
          <div
            className="appear-animation"
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="900"
            data-appear-animation-duration="750"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="700"
          >
            <h4 className="font-weight-light text-4 col-lg-6 px-0 offset-lg-3 fw-400 mb-5 opacity-8">
            Experience Findpath’s innovative solutions firsthand - request a demo today to enhance your tracking capabilities!
            </h4>
          </div>
          {/* <div className="col-12 px-0 pb-2 mb-4">
            <div className="row flex-column flex-lg-row justify-content-center">
              <div className="col-auto">
                <h5
                  className="font-weight-semibold text-4 positive-ls-2 appear-animation"
                  data-appear-animation="fadeInRightShorter"
                  data-appear-animation-delay="1100"
                  data-appear-animation-duration="750"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="800"
                >
                  <i className="fa fa-check"></i> SUPER HIGH PERFORMANCE
                </h5>
              </div>
              <div className="col-auto mx-5 my-2 my-lg-0">
                <h5
                  className="font-weight-semibold text-4 positive-ls-2 appear-animation"
                  data-appear-animation="fadeInRightShorter"
                  data-appear-animation-delay="1400"
                  data-appear-animation-duration="750"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="900"
                >
                  <i className="fa fa-check"></i> Strict Coding Standards
                </h5>
              </div>
              <div className="col-auto">
                <h5
                  className="font-weight-semibold text-4 positive-ls-2 appear-animation"
                  data-appear-animation="fadeInRightShorter"
                  data-appear-animation-delay="1600"
                  data-appear-animation-duration="750"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                >
                  <i className="fa fa-check"></i> Free Lifetime Updates
                </h5>
              </div>
            </div>
          </div> */}
          <a
            role="button"
            onClick={() => navigate("/getademo")}
            className="btn btn-dark btn-modern btn-rounded px-5 btn-py-3 text-4 appear-animation"
            data-appear-animation="fadeIn"
            data-appear-animation-delay="1800"
            data-appear-animation-duration="750"
            target="_blank"
            rel="noopener noreferrer"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="1200"
          >
            Request Demo Now
          </a>
        </div>
        <div className="row border border-start-0 border-bottom-0 border-end-0 border-color-light-2">
          <div className="col-6 col-md-3 text-center d-flex align-items-center justify-content-center py-4">
            <a
              href="#"
              className="text-decoration-none"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="icon-box"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1400"
              >
                <i className="icon-bg icon-menu-1"></i>
                <h4 className="text-4 mb-0">
                  1 Year Warranty
                  {/* <small className="d-block p-relative bottom-4 opacity-6 ls-0">
                    (SAMPLE SITES)
                  </small> */}
                </h4>
              </div>
            </a>
          </div>
          <div className="col-6 col-md-3 text-center divider-left-border border-color-light-2 d-flex align-items-center justify-content-center py-4">
            <a
              href="#"
              className="text-decoration-none"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="icon-box"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1600"
              >
                <i className="icon-bg icon-menu-2"></i>
                <h4 className="text-4 mb-0">7-Day Replacement</h4>
              </div>
            </a>
          </div>
          <div className="col-6 col-md-3 text-center divider-left-border border-color-light-2 d-flex align-items-center justify-content-center py-4">
            <a
              href="#"
              className="text-decoration-none"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="icon-box"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1800"
              >
                <i className="icon-bg icon-menu-3"></i>
                <h4 className="text-4 mb-0">Free Express Delivery</h4>
              </div>
            </a>
          </div>
          <div className="col-6 col-md-3 text-center divider-left-border border-color-light-2 d-flex align-items-center justify-content-center py-4">
            <a
              href="#"
              className="text-decoration-none"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="icon-box"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="2000"
              >
                <i className="icon-bg icon-menu-4"></i>
                <h4 className="text-4 mb-0">
                  GST Billing
                  <br />
                  {/* <span className="text-2 d-block pt-1">(coming soon)</span> */}
                </h4>
              </div>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Fmcg;
