import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // Import useParams to get slug_url from the URL
import axios from "axios";
import blog1 from "../../Assests/Blog/blog-1.jpg";
import Avtar1 from "../../Assests/Avtar/avatar-2.jpg";


const BlogPostDetail = () => {
  const { slug_url } = useParams(); // Extract slug_url from the URL parameters
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(
          `http://findpath.co.in/Backend/admin/fetch_posts.php?slug_url=${slug_url}`
        );

        setPost(response.data);
        setLoading(false);
      } catch (error) {
        console.error(
          "There was an error fetching the post!",
          error.response ? error.response.data : error.message
        );
        setError("Failed to load post.");
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug_url]); // Dependency array now includes slug_url

  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);
  }, []);

  if (loading) return <p>Loading post...</p>;
  if (error) return <p>{error}</p>;

  // Function to remove <p> tags from the description
  const sanitizeDescription = (description) => {
    return description.replace(/<\/?p>/g, "");
  };

  return (
    <div>
      {/* Blog Header Section */}
      <section
        className="page-header page-header-modern page-header-lg"
        style={{ backgroundColor: "#e3eff9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 className="text-color-dark font-weight-bold">{post.title}</h1>
            </div>
            <div className="col-md-4 order-1 order-md-2 align-self-center">
              <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
                <li>
                  <a
                    href="/"
                    className="text-color-default text-color-hover-primary text-decoration-none"
                  >
                    HOME
                  </a>
                </li>
                <li className="active">BLOG</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <div className="container py-5">
        {/* Blog Main Content */}
        <article className="mt-4">
          <img
            src={
              post.thumbnail ? `http://findpath.co.in/Backend/${post.thumbnail}` : blog1
            }
            alt={post.title}
            className="img-fluid mb-4"
          />
          <p className="text-muted">
            <span className="me-3">
              <i className="fas fa-eye"></i> {post.views || 0} Views
            </span>
            <span className="me-3">
              <i className="fas fa-thumbs-up"></i> {post.likes || 0} Likes
            </span>
            Posted on: {new Date(post.created_at).toLocaleDateString()}
          </p>
          <h2 className="text-center mt-3">{post.title}</h2>

          {/* Using dangerouslySetInnerHTML to display sanitized description */}
          <div className="content">
            <div
              dangerouslySetInnerHTML={{
                __html: sanitizeDescription(post.description),
              }}
            />
          </div>

          <hr className="my-5" />

          {/* Comments Section */}
          <div id="comments" className="post-block post-comments">
            <h3 className="text-color-primary text-capitalize font-weight-bold text-5 m-0 mb-3">
              {post.comments?.length || 0} comments for "{post.title}"
            </h3>

            <ul className="comments">
              {post.comments?.map((comment, index) => (
                <li key={index}>
                  <div className="comment">
                    <div className="img-thumbnail img-thumbnail-no-borders d-none d-sm-block">
                      <img
                        className="avatar rounded-circle"
                        alt=""
                        src={Avtar1}
                      />
                    </div>
                    <div className="comment-block">
                      <span className="comment-by">
                        <strong className="text-dark">{comment.name}</strong>
                        <span className="float-end">
                          <a href="#">
                            <i className="fas fa-reply"></i> Reply
                          </a>
                        </span>
                      </span>
                      <p>{comment.text}</p>
                      <span className="date float-end">
                        {new Date(comment.date).toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>

          <hr className="my-5" />

          {/* Leave a Comment Form */}
          <div className="post-block post-leave-comment">
            <h3 className="text-color-primary text-capitalize font-weight-bold text-5 m-0 mb-3">
              Leave a Comment
            </h3>
            <form className="contact-form p-4 rounded bg-color-grey">
              <div className="row">
                <div className="form-group col-lg-6">
                  <label className="form-label mb-1 text-2">Full Name</label>
                  <input
                    type="text"
                    className="form-control text-3 h-auto py-2"
                    name="name"
                  />
                </div>
                <div className="form-group col-lg-6">
                  <label className="form-label mb-1 text-2">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="form-control text-3 h-auto py-2"
                    name="email"
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col">
                  <label className="form-label mb-1 text-2">Comment</label>
                  <textarea
                    maxLength="5000"
                    rows="8"
                    className="form-control text-3 h-auto py-2"
                    name="comment"
                  ></textarea>
                </div>
              </div>
              <div className="row">
                <div className="form-group col">
                  <button type="submit" className="btn btn-primary">
                    Submit Comment
                  </button>
                </div>
              </div>
            </form>
          </div>
        </article>
      </div>
    </div>
  );
};

export default BlogPostDetail;
