import React, { useState, useEffect } from "react";

const ProductDetails = ({ Id }) => {
  const [productDetails, setProductDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [timeRemaining, setTimeRemaining] = useState(0);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await fetch(
          `http://localhost:8000/product-page/right-side-bar/fetchrightsidebar.php?id=${32}`
        );
        if (!response.ok) {
          throw new Error(`Error fetching product: ${response.statusText}`);
        }
        const data = await response.json();
        setProductDetails(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [Id]);

  useEffect(() => {
    const savedEndTime = localStorage.getItem("endTime");

    const calculateRemainingTime = () => {
      const remainingTime = Math.max(
        0,
        parseInt(savedEndTime) - Math.floor(Date.now() / 1000)
      );
      return remainingTime;
    };

    if (savedEndTime) {
      setTimeRemaining(calculateRemainingTime());
    } else {
      startNewTimer();
    }

    const timerId = setInterval(() => {
      setTimeRemaining((prevTime) => {
        const newTime = Math.max(prevTime - 1, 0);
        if (newTime === 0) {
          startNewTimer(); // Restart timer when it reaches zero
        }
        localStorage.setItem(
          "endTime",
          Math.floor(Date.now() / 1000) + newTime
        );
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  const startNewTimer = () => {
    const endTime = Math.floor(Date.now() / 1000) + 7 * 3600; // 7 hours in seconds
    localStorage.setItem("endTime", endTime);
    setTimeRemaining(7 * 3600);
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hours}h: ${minutes}m: ${secs}s`;
  };

  const handleQuantityChange = (event) => {
    const value = parseInt(event.target.value);
    if (!isNaN(value) && value > 0) {
      setQuantity(value);
    }
  };

  const handleQuantityIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleQuantityDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Implement add to cart functionality here
    console.log(`Added ${quantity} of ${productDetails.productName} to cart.`);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!productDetails) return <div>No product details available</div>;

  const {
    productName,
    reviews,
    short_description,
    availability,
    customName1,
    customEmoji1,
    customName2,
    customEmoji2,
    customName3,
    customEmoji3,
    customName4,
    customEmoji4,
    customName5,
    customEmoji5,
    customName6,
    customEmoji6,
    productColor,
    memoryCapacity,
    locationAccuracy,
    battery,
    deviceWeight,
    dealPrice,
    extraDiscount,
    mrp,
    discount,
    hurryMessage,
  } = productDetails;

  return (
    <div className="row">
      <div className="summary entry-summary position-relative">
        <h1 className="mb-0 font-weight-bold text-7">
          {productName}
          <span
            className="bold"
            style={{ marginLeft: "5px", fontSize: "20px", color: "#40c351" }}
          >
            (2G)
          </span>
        </h1>

        {/* Custom names and emojis section */}
        <div className="custom-name-emoji d-flex flex-column align-items-start">
          <div className="row">
            <div className="d-flex">
              {[customName6, customName3, customName5].map((name, index) => (
                <div className="d-flex align-items-center" key={index}>
                  <span
                    className="emoji"
                    style={{
                      marginRight: "7px",
                      marginLeft: "7px",
                      fontSize: "15px",
                    }}
                  >
                    {[customEmoji6, customEmoji3, customEmoji5][index]}
                  </span>
                  <span className="custom-name text-color-dark text-2">
                    {name}
                  </span>
                </div>
              ))}
            </div>
            <div className="d-flex">
              {[customName2, customName4, customName1].map((name, index) => (
                <div className="d-flex align-items-center" key={index}>
                  <span
                    className="emoji"
                    style={{
                      marginRight: "7px",
                      marginLeft: "7px",
                      fontSize: "15px",
                    }}
                  >
                    {[customEmoji2, customEmoji4, customEmoji1][index]}
                  </span>
                  <span className="custom-name text-color-dark text-2">
                    {name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="pb-0 clearfix d-flex align-items-center">
          <div className="review-num">
            <a
              href="#description"
              className="text-decoration-none text-color-default text-color-hover-primary"
            >
              <span className="count text-color-inherit">({reviews})</span>{" "}
              reviews
            </a>
          </div>
        </div>
        <div className="divider divider-small mt-3 mb-3">
          <hr className="bg-color-grey-400 m-0" />
        </div>

        {/* Extra Discount Message */}
        <div style={{ color: "red", fontSize: "14px", fontWeight: "700" }}>
          Extra <span id="rupee">₹</span>
          {extraDiscount} discount
        </div>

        <p className="price mb-0">
          <strong>
            <span className="text-color-dark">Deal Price: ₹{dealPrice}</span>
          </strong>
          <strong
            className="bold"
            style={{ marginLeft: "5px", fontSize: "25px", color: "red" }}
          >
            {discount}
          </strong>
        </p>

        {/* M.R.P. */}
        <div
          style={{
            fontFamily: "PT Sans",
            fontSize: "20px",
            lineHeight: "24px",
          }}
        >
          <span style={{ color: "grey" }}>M.R.P: </span>
          <span
            style={{
              textDecoration: "line-through",
              color: "grey",
              fontSize: "22px",
              fontWeight: "initial",
            }}
          >
            <span id="rupee">₹</span>
            {mrp}
          </span>
        </div>
        <div className="tax ng-star-inserted mb-2">*Inclusive of all taxes</div>

        <p className="text-3-5 mb-3">{short_description}</p>

        {/* Technical specifications section */}
        <ul className="list list-unstyled text-2">
          <li className="mb-0">
            AVAILABILITY:{" "}
            <strong className="text-color-dark">{availability}</strong>
          </li>
          <li className="mb-0">
            Color: <strong className="text-color-dark">{productColor}</strong>
          </li>
          <li className="mb-0">
            Battery: <strong className="text-color-dark">{battery}</strong>
          </li>
          <li className="mb-0">
            Device Weight:{" "}
            <strong className="text-color-dark">{deviceWeight}</strong>
          </li>
          <li className="mb-0">
            Memory Capacity:{" "}
            <strong className="text-color-dark">{memoryCapacity}</strong>
          </li>
          <li className="mb-0">
            Location Accuracy:{" "}
            <strong className="text-color-dark">{locationAccuracy}</strong>
          </li>
        </ul>

        {/* Sale Timer */}
        <div style={{ fontSize: "18px", fontWeight: "bold", color: "black" }}>
            <span>Ends in </span>
            <span style={{ color: "#FF3333" }}>
              {formatTime(Math.floor(timeRemaining))}{" "}
              {/* Ensure timeRemaining is a whole number */}
            </span>
          </div>

        <div style={{ fontSize: "14px", color: "red", marginBottom: "2px" }}>
          {hurryMessage}
        </div>
        {/* Quantity Selector */}
        <form method="post" className="cart" onSubmit={handleSubmit}>
          <hr />
          <div className="quantity quantity-lg">
            <button
              type="button"
              className="minus text-color-hover-light bg-color-hover-primary border-color-hover-primary"
              onClick={handleQuantityDecrement}
            >
              -
            </button>
            <input
              type="text"
              className="input-text qty text"
              title="Qty"
              value={quantity}
              name="quantity"
              onChange={handleQuantityChange}
              min="1"
              step="1"
            />
            <button
              type="button"
              className="plus text-color-hover-light bg-color-hover-primary border-color-hover-primary"
              onClick={handleQuantityIncrement}
            >
              +
            </button>
          </div>
          <button
            type="submit"
            className="btn btn-dark btn-modern text-uppercase bg-color-hover-primary border-color-hover-primary"
          >
            Add to cart
          </button>
          <hr />
        </form>

        <div className="d-flex align-items-center">
          <ul className="social-icons social-icons-medium social-icons-clean-with-border social-icons-clean-with-border-border-grey social-icons-clean-with-border-icon-dark me-3 mb-0">
            <li className="social-icons-facebook">
              <a
                href="http://www.facebook.com/sharer.php?u=https://www.okler.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li className="social-icons-googleplus">
              <a
                href="https://plus.google.com/share?url=https://www.okler.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-google-plus-g"></i>
              </a>
            </li>
            <li className="social-icons-twitter">
              <a
                href="https://twitter.com/share?url=https://www.okler.net&amp;text=Simple%20Share%20Buttons&amp;hashtags=simplesharebuttons"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li className="social-icons-email">
              <a href="mailto:?Subject=Share This Page&amp;Body=I%20saw%20this%20and%20thought%20of%20you!%20https://www.okler.net">
                <i className="far fa-envelope"></i>
              </a>
            </li>
          </ul>
          <a
            href="#"
            className="d-flex align-items-center text-decoration-none text-color-dark text-color-hover-primary font-weight-semibold text-2"
          >
            <i className="far fa-heart me-1"></i> SAVE TO WISHLIST
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
