import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";

const ImageUploadForm = () => {
  const [selectedImages, setSelectedImages] = useState({
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  });
  const [productId, setProductId] = useState(""); // State for product_id
  const [previewUrls, setPreviewUrls] = useState({
    image1: null,
    image2: null,
    image3: null,
    image4: null,
    image5: null,
  });
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleImageChange = (event, imageKey) => {
    const file = event.target.files[0];
    setSelectedImages((prevState) => ({
      ...prevState,
      [imageKey]: file,
    }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrls((prevState) => ({
        ...prevState,
        [imageKey]: reader.result,
      }));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("product_id", productId); // Add product_id to FormData

    Object.keys(selectedImages).forEach((key) => {
      if (selectedImages[key]) {
        formData.append(key, selectedImages[key]);
      }
    });

    try {
      const response = await axios.post(
        "http://localhost:8000/product-page/middle-img-bar/uploadimg.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.status === "success") {
        setMessage(response.data.message || "Images uploaded successfully!");
        setProductId(""); // Reset product_id field
        setSelectedImages({
          image1: null,
          image2: null,
          image3: null,
          image4: null,
          image5: null,
        });
        setPreviewUrls({
          image1: null,
          image2: null,
          image3: null,
          image4: null,
          image5: null,
        });
      } else {
        setMessage(response.data.message || "Error uploading images");
      }
      setTimeout(() => setMessage(""), 3000);
    } catch (error) {
      setMessage("Error uploading images: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (message.includes("successfully")) {
      const timer = setTimeout(() => {
        setMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  return (
    <div className="container my-4">
      <h3 className="text-center mb-4">Upload Product Images (5 images)</h3>
      <form
        onSubmit={handleSubmit}
        className="d-flex flex-column align-items-center"
      >
        <div className="form-group mb-3 w-100">
          <label>Product ID</label>
          <input
            type="text"
            className="form-control"
            value={productId}
            onChange={(e) => setProductId(e.target.value)}
            required
          />
        </div>

        {[1, 2, 3, 4, 5].map((num) => (
          <div className="form-group mb-3" key={`image_${num}`}>
            <label>Upload Image {num}</label>
            <input
              type="file"
              className="form-control"
              onChange={(event) => handleImageChange(event, `image${num}`)}
              accept="image/*"
              required
            />
            {previewUrls[`image${num}`] && (
              <img
                src={previewUrls[`image${num}`]}
                alt={`Preview ${num}`}
                className="img-thumbnail mt-2"
                style={{ maxHeight: "150px", borderRadius: "10px" }}
              />
            )}
          </div>
        ))}

        <button type="submit" className="btn btn-primary" disabled={loading}>
          {loading ? "Uploading..." : "Upload Images"}
        </button>

        {message && (
          <div
            className={`alert ${
              message.includes("successfully")
                ? "alert-success"
                : "alert-danger"
            } mt-3 w-100`}
            role="alert"
          >
            {message}
          </div>
        )}
      </form>
    </div>
  );
};

export default ImageUploadForm;
