import React, { useState } from 'react';
import axios from 'axios';
import './UploadRightSideBar.css'; // Assuming you have a separate CSS file for styling

const ProductUpload = () => {
  const [formData, setFormData] = useState({
    productName: '',
    reviews: '',
    short_description: '',
    long_description: '',
    additional_info: '',
    availability: '',
    customName1: '',
    customEmoji1: '',
    customName2: '',
    customEmoji2: '',
    customName3: '',
    customEmoji3: '',
    customName4: '',
    customEmoji4: '',
    customName5: '',
    customEmoji5: '',
    customName6: '',
    customEmoji6: '',
    productColor: '',
    memoryCapacity: '',
    locationAccurancy: '',
    battery: '',
    deviceWeight: '',
    dealPrice: '',
    extraDiscount: '',
    mrp: '',
    discount: '',
    hurryMessage: '',
  });

  // Handle form input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:8000/product-page/right-side-bar/rightsideinsertdata.php', formData);
      alert(response.data.message);  // Success message
    } catch (error) {
      console.error('Error uploading product', error);
      alert('Failed to upload the product. Please try again.');  // Error message
    }
  };

  return (
    <form onSubmit={handleSubmit} className="product-upload-form">
      <h2 className="form-title">Upload Product</h2>

      {/* Product Name and Reviews */}
      <div className="form-row">
        <input
          type="text"
          name="productName"
          placeholder="Product Name"
          value={formData.productName}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="reviews"
          placeholder="Reviews"
          value={formData.reviews}
          onChange={handleChange}
        />
      </div>

      {/* Short and Long Descriptions */}
      <div className="form-row">
        <input
          type="text"
          name="short_description"
          placeholder="Short Description"
          value={formData.short_description}
          onChange={handleChange}
        />
        <input
          type="text"
          name="long_description"
          placeholder="Long Description"
          value={formData.long_description}
          onChange={handleChange}
        />
      </div>

      {/* Additional Info and Availability */}
      <div className="form-row">
        <input
          type="text"
          name="additional_info"
          placeholder="Additional Info"
          value={formData.additional_info}
          onChange={handleChange}
        />
        <input
          type="text"
          name="availability"
          placeholder="Availability"
          value={formData.availability}
          onChange={handleChange}
        />
      </div>

      {/* Custom Names and Emojis */}
      {[...Array(6)].map((_, index) => (
        <div className="form-row" key={index}>
          <input
            type="text"
            name={`customName${index + 1}`}
            placeholder={`Custom Name ${index + 1}`}
            value={formData[`customName${index + 1}`]}
            onChange={handleChange}
          />
          <input
            type="text"
            name={`customEmoji${index + 1}`}
            placeholder={`Custom Emoji ${index + 1}`}
            value={formData[`customEmoji${index + 1}`]}
            onChange={handleChange}
          />
        </div>
      ))}

      {/* Product Specifications */}
      <div className="form-row">
        <input
          type="text"
          name="productColor"
          placeholder="Product Color"
          value={formData.productColor}
          onChange={handleChange}
        />
        <input
          type="text"
          name="memoryCapacity"
          placeholder="Memory Capacity"
          value={formData.memoryCapacity}
          onChange={handleChange}
        />
      </div>

      <div className="form-row">
        <input
          type="text"
          name="locationAccurancy"
          placeholder="Location Accuracy"
          value={formData.locationAccurancy}
          onChange={handleChange}
        />
        <input
          type="text"
          name="battery"
          placeholder="Battery"
          value={formData.battery}
          onChange={handleChange}
        />
      </div>

      <div className="form-row">
        <input
          type="text"
          name="deviceWeight"
          placeholder="Device Weight"
          value={formData.deviceWeight}
          onChange={handleChange}
        />
        <input
          type="text"
          name="dealPrice"
          placeholder="Deal Price"
          value={formData.dealPrice}
          onChange={handleChange}
        />
      </div>

      {/* Pricing and Discounts */}
      <div className="form-row">
        <input
          type="text"
          name="extraDiscount"
          placeholder="Extra Discount"
          value={formData.extraDiscount}
          onChange={handleChange}
        />
        <input
          type="text"
          name="mrp"
          placeholder="MRP"
          value={formData.mrp}
          onChange={handleChange}
        />
      </div>

      <div className="form-row">
        <input
          type="text"
          name="discount"
          placeholder="Discount"
          value={formData.discount}
          onChange={handleChange}
        />
        <input
          type="text"
          name="hurryMessage"
          placeholder="Hurry Message"
          value={formData.hurryMessage}
          onChange={handleChange}
        />
      </div>

      <button type="submit" className="submit-btn">Submit Product</button>
    </form>
  );
};

export default ProductUpload;
