import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./MiddleImgBar.css";
import axios from 'axios';

const MiddleImgBar = ({ Id }) => {
  const [images, setImages] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(`http://localhost:8000/product-page/middle-img-bar/fetchmiddleimgbar.php?id=${11}`); // Use the passed Id
        
        if (response.data.status === "success") {
          // console.log(response.data.data)
          // Map the image paths from the response
          const imagePaths = [
            response.data.data[0],
            response.data.data[1],
            response.data.data[2],
            response.data.data[3],
            response.data.data[4],
          ].map(imageName => `http://localhost:8000/product-page/${imageName}`);
          
          setImages(imagePaths);
        } else {
          console.error(response.data.message);
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, [Id]);

  const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  return (
    <div className="thumb-gallery-wrapper">
      <Carousel
        activeIndex={activeIndex}
        onSelect={handleSelect}
        interval={null}
        controls={true}
        indicators={false}
      >
        {images.slice(0, 5).map((src, index) => (
          <Carousel.Item key={index}>
            <img
              className="d-block w-100"
              src={src}
              alt={`Slide ${index + 1}`}
            />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="d-flex justify-content-center align-items-center mt-3">
        {images.slice(0, 5).map((src, index) => (
          <div
            key={index}
            className={`thumb mx-1 ${index === activeIndex ? "border border-primary" : ""}`}
            onClick={() => handleSelect(index)}
            style={{ cursor: "pointer" }}
          >
            <img
              src={src}
              alt={`Thumbnail ${index + 1}`}
              className="img-thumbnail"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MiddleImgBar;
