import React, { useState, useEffect } from "react";
import axios from "axios";
import { countries, states as allStates } from "../../js/country-state"; // Adjust the import path as necessary
import { useNavigate } from "react-router-dom";
const PartnerWithUs = () => {
  const navigate = useNavigate(); // Corrected useNavigate() usage
  useEffect(() => {
    // Scroll to the top of the page on component mount
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    fullName: "",
    companyName: "",
    phone: "",
    email: "",
    city: "",
    state: "",
    country: "",
    fleetSize: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState("");
  const [alertClass, setAlertClass] = useState("");
  const [statesList, setStatesList] = useState([]);

  useEffect(() => {
    if (formData.country) {
      setStatesList(allStates[formData.country] || []);
    } else {
      setStatesList([]);
    }
  }, [formData.country]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.fullName) newErrors.fullName = "Full Name is required.";
    if (!formData.companyName)
      newErrors.companyName = "Company Name is required.";

    if (!formData.phone) newErrors.phone = "Phone Number is required.";
    if (!formData.email) {
      newErrors.email = "Email Address is required.";
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!formData.city) newErrors.city = "City is required.";
    if (!formData.state) newErrors.state = "State is required.";
    if (!formData.country) newErrors.country = "Country is required.";
    if (!formData.fleetSize) newErrors.fleetSize = "Fleet Size is required.";
   
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setStatus("Please fill out all required fields correctly.");
      setAlertClass("alert alert-danger d-block mt-4");
      return;
    }

    axios
      .post("http://findpath.co.in/Backend/partner_with_us.php", formData)
      .then((response) => {
        if (response.data.success) {
          setStatus("Success! Your message has been sent.");
          setAlertClass("alert alert-success d-block mt-4");
          setFormData({
            fullName: "",
            companyName: "",
            phone: "",
            email: "",
            country: "",
            state: "",
            city: "",
            fleetSize: "",
            message: "",
          });
          navigate("/thank-you"); // Navigate to the thank-you page
        } else {
          setStatus(`Error! ${response.data.message}`);
          setAlertClass("alert alert-danger d-block mt-4");
        }
      })
      .catch((error) => {
        setStatus(`Outer Error! ${error.message}`);
        setAlertClass("alert alert-danger d-block mt-4");
      });
  };

  const inputStyle = { backgroundColor: "#e3eff9" }; // Inline style for background color

  return (
    <div className="container py-5">
      <h2 className="text-center font-weight-bold mb-4">Partner With Us</h2>
      <div className="row justify-content-center">
        <div className="col-lg-10 col-xl-8">
          <form
            className="contact-form custom-form-style-1 border border-primary p-4 rounded"
            noValidate="novalidate"
            onSubmit={handleSubmit}
          >
            <div className={alertClass} role="alert">
              {status}
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">Full Name</label>
                <input
                  type="text"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.fullName ? "is-invalid" : ""
                  }`}
                  name="fullName"
                  value={formData.fullName}
                  onChange={(e) => {
                    // Keep only alphabetic characters and convert to lowercase
                    const value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
                    handleChange({
                      target: {
                        name: "fullName",
                        value: value,
                      },
                    });
                  }}
                  style={inputStyle} // Apply background color
                />
                {errors.fullName && (
                  <div className="invalid-feedback">{errors.fullName}</div>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">Company Name</label>
                <input
                  type="text"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.companyName ? "is-invalid" : ""
                  }`}
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                />
                {errors.companyName && (
                  <div className="invalid-feedback">{errors.companyName}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">Phone Number</label>
                <input
                  type="tel"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.phone ? "is-invalid" : ""
                  }`}
                  name="phone"
                  value={formData.phone}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only digits and ensure the length does not exceed 10
                    if (/^\d*$/.test(value) ) {
                      handleChange(e);
                    }
                  }}
                  style={inputStyle} // Apply background color
                />
                {errors.phone && (
                  <div className="invalid-feedback">{errors.phone}</div>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">Email Address</label>
                <input
                  type="email"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.email ? "is-invalid" : ""
                  }`}
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">Country</label>
                <select
                  name="country"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.country ? "is-invalid" : ""
                  }`}
                  value={formData.country}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                >
                  <option value="">Select Country</option>
                  {countries.map((country) => (
                    <option key={country.code} value={country.code}>
                      {country.name} {country.dialCode}
                    </option>
                  ))}
                </select>
                {errors.country && (
                  <div className="invalid-feedback">{errors.country}</div>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">State</label>
                <select
                  name="state"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.state ? "is-invalid" : ""
                  }`}
                  value={formData.state}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                >
                  <option value="">Select State</option>
                  {statesList.map((state, index) => (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <div className="invalid-feedback">{errors.state}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">City</label>
                <input
                  type="text"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.city ? "is-invalid" : ""
                  }`}
                  name="city"
                  value={formData.city}
                  onChange={(e) => {
                    // Keep only alphabetic characters and convert to lowercase
                    const value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
                    handleChange({
                      target: {
                        name: "city",
                        value: value,
                      },
                    });
                  }}
                  style={inputStyle} // Apply background color
                />
                {errors.city && (
                  <div className="invalid-feedback">{errors.city}</div>
                )}
              </div>
              <div className="form-group col-md-6">
                <label className="form-label mb-1 text-2">Fleet Size</label>
                <select
                  name="fleetSize"
                  className={`form-control text-3 h-auto py-2 ${
                    errors.fleetSize ? "is-invalid" : ""
                  }`}
                  value={formData.fleetSize}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                >
                  <option value="">Select Fleet Size</option>
                  <option value="1-10">1-10</option>
                  <option value="10-50">10-50</option>
                  <option value="50-100">50-100</option>
                  <option value="100-500">100-500</option>
                  <option value="500-1000">500-1000</option>
                  <option value="500-1000">1000-1500</option>
                  <option value="500-1000">1500-2000</option>

                </select>
                {errors.fleetSize && (
                  <div className="invalid-feedback">{errors.fleetSize}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col">
                <label className="form-label mb-1 text-2">Message</label>
                <textarea
                  className={`form-control text-3 h-auto py-2 ${
                    errors.message ? "is-invalid" : ""
                  }`}
                  name="message"
                  rows="5"
                  value={formData.message}
                  onChange={handleChange}
                  style={inputStyle} // Apply background color
                ></textarea>
                {errors.message && (
                  <div className="invalid-feedback">{errors.message}</div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="form-group col text-center">
                <button
                  type="submit"
                  className="btn btn-primary btn-modern font-weight-bold custom-btn-border-radius custom-btn-arrow-effect-1 text-3 px-5 py-3"
                  data-loading-text="Loading..."
                >
                  Submit
                  <svg
                    className="ms-2"
                    version="1.1"
                    viewBox="0 0 15.698 8.706"
                    width="17"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <polygon
                      stroke="#FFF"
                      strokeWidth="0.1"
                      fill="#FFF"
                      points="11.354,0 10.646,0.706 13.786,3.853 0,3.853 0,4.853 13.786,4.853 10.646,8 11.354,8.706 15.698,4.353"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PartnerWithUs;
