import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// BlogHeader Component
const BlogHeader = () => {
  const navigate = useNavigate();

  return (
    <section
      className="page-header page-header-modern page-header-lg"
      style={{ backgroundColor: "#e3eff9" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
            <h1 className="text-color-dark font-weight-bold">Blog</h1>
          </div>
          <div className="col-md-4 order-1 order-md-2 align-self-center">
            <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
              <li>
                <a
                  role="button"
                  onClick={() => navigate("/")}
                  className="text-color-default text-color-hover-primary text-decoration-none"
                >
                  HOME
                </a>
              </li>
              <li className="active">BLOG</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

// BlogPost Component with Like Button (Heart Icon) and Summary View
const BlogPost = ({
  date,
  comments,
  author,
  title,
  description,
  imageUrl,
  postUrl,
}) => {
  const navigate = useNavigate();
  const [liked, setLiked] = useState(false);

  const handleNavigation = () => {
    navigate(postUrl);
  };

  const toggleLike = () => {
    setLiked(!liked);
  };

  // Function to truncate description to a summary (e.g., first 150 characters)
  const truncateDescription = (desc, maxLength) => {
    if (desc.length > maxLength) {
      return desc.substring(0, maxLength) + "...";
    }
    return desc;
  };

  return (
    <article className="mb-5">
      <div className="card bg-transparent border-0 custom-border-radius-1">
        <div className="card-body p-0 z-index-1">
          <img
            className="card-img-top custom-border-radius-1 mb-2"
            onClick={handleNavigation}
            src={`http://findpath.co.in/Backend/${imageUrl}`}
            alt="Card Image"
            style={{ cursor: "pointer" }}
          />
          <p className="text-uppercase text-color-default text-1 my-2 d-flex align-items-center">
            <time pubdate="" dateTime={date}>
              {date}
            </time>
            <span className="opacity-3 d-inline-block px-2">|</span>
            {comments} Comments
            <span className="opacity-3 d-inline-block px-2">|</span>
            {author}
            <button
              onClick={toggleLike}
              style={{
                border: "none",
                background: "transparent",
                cursor: "pointer",
                marginLeft: "15px",
              }}
              aria-label="Like"
            >
              <i
                className={`fa${liked ? "s" : "r"} fa-heart`}
                style={{
                  color: liked ? "red" : "black",
                  fontSize: "16px",
                }}
              ></i>
            </button>
          </p>
          <div className="card-body p-0">
            <h4 className="card-title text-5 font-weight-bold pb-1 mb-2">
              <span
                className="text-color-dark text-color-hover-primary text-decoration-none"
                onClick={handleNavigation}
                style={{ cursor: "pointer" }}
              >
                {title}
              </span>
            </h4>
            <p className="card-text mb-2">
              {truncateDescription(description, 150)}
            </p>
            <a
              onClick={handleNavigation}
              className="text-color-primary"
              style={{ cursor: "pointer" }}
            >
              Read More
            </a>
          </div>
        </div>
      </div>
    </article>
  );
};

// Pagination Component
const Pagination = ({ currentPage, totalPages, setCurrentPage }) => {
  const handlePageClick = (e, page) => {
    e.preventDefault(); // Prevent default page jump
    setCurrentPage(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <a
            className="page-link"
            href="#"
            onClick={(e) => handlePageClick(e, i)}
          >
            {i}
          </a>
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <ul className="custom-pagination-style-1 pagination pagination-rounded pagination-md justify-content-center">
      <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
        <a
          className="page-link"
          href="#"
          onClick={(e) => handlePageClick(e, currentPage - 1)}
        >
          <i className="fas fa-angle-left"></i>
        </a>
      </li>
      {renderPageNumbers()}
      <li className={`page-item ${currentPage === totalPages ? "disabled" : ""}`}>
        <a
          className="page-link"
          href="#"
          onClick={(e) => handlePageClick(e, currentPage + 1)}
        >
          <i className="fas fa-angle-right"></i>
        </a>
      </li>
    </ul>
  );
};

// BlogMainContent Component
const BlogMainContent = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 4;

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          "http://findpath.co.in/Backend/admin/fetch_posts.php"
        );
        setPosts(response.data.reverse());
        setLoading(false);
      } catch (error) {
        setError("Failed to load posts.");
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) return <p>Loading posts...</p>;
  if (error) return <p>{error}</p>;

  const totalPages = Math.ceil(posts.length / postsPerPage);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const sanitizeDescription = (description) => {
    return description.replace(/<\/?p>/g, "").replace(/&nbsp;/g, " ");
  };

  return (
    <div
      className="col-12 col-md-12 col-lg-8 mb-5 mb-lg-0 appear-animation animated fadeInUpShorter"
      data-appear-animation="fadeInUpShorter"
      data-appear-animation-delay="1600"
      style={{ animationDelay: "1600ms" }}
    >
      {currentPosts.map((post) => (
        <BlogPost
          key={post.id}
          date={new Date(post.created_at).toLocaleDateString()}
          comments="0"
          author="Author Name"
          title={post.title}
          description={sanitizeDescription(post.description)}
          imageUrl={post.thumbnail}
          postUrl={`/blogpost/${post.slug_url}`}
        />
      ))}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

// BlogPage Component
const BlogPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <BlogHeader />
      <div className="container py-3 my-4 d-flex justify-content-center">
        <BlogMainContent />
      </div>
    </div>
  );
};

export default BlogPage;
