import React, { useEffect } from "react";
import About1 from "../../Assests/about1.jpg";
import About2 from "../../Assests/about2.jpg";

import aboutusWallLogo from "../../Assests/aboutus-wall-logo.jpg";
import "../AboutUs/AboutUs.css";

const BlogHeader = () => (
  <section
    className="page-header page-header-modern page-header-lg"
    style={{
      backgroundColor: "#e3eff9",
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
          <h1 className="text-color-dark font-weight-bold">About us</h1>
        </div>
        <div className="col-md-4 order-1 order-md-2 align-self-center">
          <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
            <li>
              <a
                href="/"
                className="text-color-default text-color-hover-primary text-decoration-none"
              >
                HOME
              </a>
            </li>
            <li className="active">About us</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

const AboutUs = () => {
  useEffect(() => {
    const counters = document.querySelectorAll(".counter strong");

    const options = {
      rootMargin: "0px",
      threshold: 0.2, // Trigger when 20% of the element is in view
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const target = +entry.target.getAttribute("data-to");
          const append = entry.target.getAttribute("data-append") || "";

          let count = 0;
          const updateCount = () => {
            const speed = 200; // Change animation speed here
            const increment = target / speed;

            if (count < target) {
              count += increment;
              entry.target.innerText = Math.ceil(count) + append;
              setTimeout(updateCount, 1);
            } else {
              entry.target.innerText = target + append;
            }
          };

          updateCount();
          observer.unobserve(entry.target);
        }
      });
    }, options);

    counters.forEach((counter) => {
      observer.observe(counter);
    });

    return () => {
      observer.disconnect();
    };
  }, []);
  useEffect(() => {
    // Scroll to top on initial load
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <BlogHeader />
      <div class="container">
        <div class="row pt-5">
          <div class="col">
            <div class="row text-center pb-5">
              <div class="col-md-6 mx-md-auto d-flex align-items-center">
                <img
                  src={aboutusWallLogo}
                  alt="Image"
                  width="570"
                  height="400"
                  className="rounded-2 img-fluid"
                />
              </div>
              <div class="col-md-6 mx-md-auto">
                <div class="overflow-hidden mb-3">
                  <h1
                    class="word-rotator slide font-weight-bold text-8 mb-0 appear-animation appear-animation-visible"
                    style={{ marginTop: "17px" }}
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    data-aos-delay="500"
                  >
                    <span
                      class="word-rotator-words bg-primary rounded-3 "
                      style={{ marginLeft: "6px" }}
                    ></span>
                    <span> Findpath : India’s Leading GPS Brand</span>
                  </h1>
                </div>
                <div class="overflow-hidden mb-3">
                  <p
                    class=" mb-0 appear-animation appear-animation-visible"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    data-aos-delay="700"
                    style={{ marginLeft: "6px" }}
                  >
                    Founded in 2018 in Delhi, SM Surveillance Private Limited is
                    a leading provider of premium GPS tracking solutions in
                    India. We specialize in real-time tracking for assets,
                    vehicles, and individuals, offering cutting-edge solutions
                    like E-Lock, Fleet Management, Fuel Monitoring, Video
                    Telematics, Driver Behaviour analysis, Route Planning, and
                    Electric Vehicle Management. Our advanced technology ensures
                    accurate location data, providing security and peace of
                    mind.
                    <br />
                    <br />
                    We are dedicated to delivering top-tier technology and
                    exceptional customer service, empowering businesses with
                    data-driven insights to enhance efficiency and safeguard
                    their assets. At SM Surveillance Private Limited, we are
                    committed to exceeding customer expectations with our
                    innovative tracking solutions.
                  </p>
                </div>
              </div>
            </div>

            <div class="row mt-3 mb-5">
              <div class="col-md-4">
                <div
                  class="appear-animation appear-animation-visible"
                  data-aos="fade-right"
                  data-aos-duration="1900"
                  data-aos-delay="800"
                >
                  <h3 class="font-weight-bold text-4 mb-2">Our Mission</h3>
                  <p>
                    At Findpath, our mission is to pioneer excellence in GPS and
                    surveillance technology, setting a benchmark in asset
                    security and management. We deliver premier solutions
                    characterized by precision, innovation, and reliability. We
                    empower our clients with clarity and confidence. We don’t
                    just provide technology but also craft peace of mind and
                    elevate experiences to the highest level of sophistication
                    and security.
                  </p>
                </div>
              </div>

              <div class="col-md-4">
                <div
                  class="appear-animation appear-animation-visible"
                  data-aos="fade-right"
                  data-aos-duration="1700"
                  data-aos-delay="650"
                >
                  <h3 class="font-weight-bold text-4 mb-2">Our Vision</h3>
                  <p>
                    At Findpath, our vision is to epitomize the pinnacle of
                    excellence in GPS and surveillance technology. We aspire to
                    offer the most refined and sophisticated solutions for asset
                    protection and management. We envision a future where
                    Findpath is synonymous with unmatched quality and trust,
                    empowering our esteemed clients to navigate their world with
                    unparalleled assurance and peace of mind.
                  </p>
                </div>
              </div>

              <div class="col-md-4">
                <div
                  class="appear-animation appear-animation-visible"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                  data-aos-delay="500"
                >
                  <h3 class="font-weight-bold text-4 mb-2">Our Values</h3>
                  <p>
                    At Findpath, our values are at the heart of what we do. We
                    believe in delivering trustworthy and innovative GPS
                    tracking solutions that keep you connected and secure. Our
                    commitment to excellence drives us to provide unparalleled
                    service and reliability, ensuring peace of mind for every
                    user.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section
        className="section section-primary border-0 mb-0 appear-animation appear-animation-visible"
        data-aos="fade-in"
      >
        <div className="container">
          <div className="row counters counters-sm pb-4 pt-3">
            <div className="col-sm-6 col-lg-3 mb-5 mb-lg-0" data-aos="fade-up">
              <div className="counter">
                <i className="icons icon-user text-color-light"></i>
                <strong
                  className="text-color-light font-weight-extra-bold"
                  data-to="25000"
                  data-append="+"
                >
                  0
                </strong>
                <label className="text-4 mt-1 text-color-light">
                  Happy Clients
                </label>
              </div>
            </div>
            <div
              className="col-sm-6 col-lg-3 mb-5 mb-lg-0"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="counter">
                <i className="icons icon-badge text-color-light"></i>
                <strong
                  className="text-color-light font-weight-extra-bold"
                  data-to="5"
                  data-append="+"
                >
                  0
                </strong>
                <label className="text-4 mt-1 text-color-light">
                  Years In Business
                </label>
              </div>
            </div>
            <div
              className="col-sm-6 col-lg-3 mb-5 mb-sm-0"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <div className="counter">
                <i className="icons icon-graph text-color-light"></i>
                <strong
                  className="text-color-light font-weight-extra-bold"
                  data-to="100000"
                  data-append="+"
                >
                  0
                </strong>
                <label className="text-4 mt-1 text-color-light">
                  Business High Score
                </label>
              </div>
            </div>
            <div
              className="col-sm-6 col-lg-3"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              <div className="counter">
                <i className="icons icon-flag text-color-light"></i>
                <strong
                  className="text-color-light font-weight-extra-bold"
                  data-to="20"
                  data-append="+"
                >
                  0
                </strong>
                <label className="text-4 mt-1 text-color-light">States</label>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid p-0">
        {/* First Row */}
        <div className="row align-items-center bg-color-grey">
          <div className="col-lg-6 p-0">
            <section
              className="section section-no-border custom-sec-left h-100 m-0"
              style={{
                width: "100%", // Make the section take full width
                minHeight: "50vh", // Adjust height to make it responsive (100% of viewport height)
                backgroundImage: `url(${About1})`,
                backgroundSize: "cover", // Cover the entire section with the image
                backgroundRepeat: "no-repeat", // Prevent repeating the image
                backgroundPosition: "center center", // Center the image
              }}
            ></section>
          </div>
          <div className="col-lg-6 p-0">
            <section className="section section-no-border h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-left">
                  <div className="overflow-hidden">
                    <h4
                      className="mb-0"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="100"
                    >
                      <a
                        href="#"
                        className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1"
                      >
                        Who We Are
                      </a>
                    </h4>
                  </div>
                  <p
                    className="text-2 mb-0"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                  >
                    At Findpath, we specialize in advanced GPS and tracking
                    solutions for all types of vehicles and personal use. Our
                    commitment is to provide seamless and reliable technology
                    that enhances safety and security, ensuring peace of mind
                    for our users.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>

        {/* Second Row */}
        <div className="row align-items-center bg-color-grey">
          <div className="col-lg-6 order-2 order-lg-1 p-0">
            <section className="section section-no-border h-100 m-0">
              <div className="row justify-content-end m-0">
                <div className="col-half-section col-half-section-right custom-text-align-right">
                  <div className="overflow-hidden">
                    <h4
                      className="mb-0"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="100"
                    >
                      <a
                        href="#"
                        className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1"
                      >
                        Why Choose Us
                      </a>
                    </h4>
                  </div>
                  <p
                    className="text-2 mb-0"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                  >
                    At Findpath, we offer an unparalleled fusion of cutting-edge
                    technology and bespoke service, ensuring that our GPS and
                    surveillance solutions are second to none. Our esteemed
                    reputation is a testament to our unwavering commitment to
                    reliability and innovation. By choosing us, you are choosing
                    a brand that is dedicated to exceed your expectations and
                    deliver seamless experiences. Let us guide you with
                    uncompromising quality.
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 p-0">
            <section
              className="section section-no-border h-100 m-0"
              style={{
                width: "100%", // Make the section take full width
                minHeight: "50vh", // Adjust height to make it responsive (100% of viewport height)
                backgroundImage: `url(${About2})`,
                backgroundSize: "cover", // Cover the entire section with the image
                backgroundRepeat: "no-repeat", // Prevent repeating the image
                backgroundPosition: "center center", // Center the image
              }}
            ></section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
