import React from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import logo from "../../Assests/footerlogo.png";
import "../Footer/Footer.css";
import hoverImage from "../../Assests/logo.png";
import appdownload1 from "../../Assests/app-download-1.png";
import appdownload2 from "../../Assests/app-download-2.png";
import addicon from "../../Assests/icon-location.svg";
import support from "../../Assests/24x7.svg";
import officeTime from "../../Assests/Opening-Hours.svg";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer id="footer" className="border-0 mt-0">
      <div className="container py-5">
        <div className="row py-3">
          {/* Address */}
          <div className="col-md-3 mb-5 mb-md-0">
            <div className="feature-box flex-column flex-xl-row align-items-center align-items-lg-start text-center text-lg-start">
              {/* Address Icon */}
              <div className="feature-box-icon bg-transparent mb-4 mb-xl-0 p-0">
                <img
                  width="45"
                  src={addicon}
                  alt="add-icon"
                  data-icon
                  data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light position-relative bottom-3'}"
                />
              </div>
              {/* Address Info */}
              <div className="feature-box-info line-height-1 ps-2">
                <span className="d-block font-weight-bold text-color-light text-5 mb-2">
                  Address
                </span>
                <p className="text-color-light text-4 line-height-4 font-weight-light mb-0">
                  C-591, Avantika Rohini, Sector-1 Delhi - 110085
                </p>
              </div>
            </div>
          </div>
          {/* Call Us */}
          <div className="col-md-3 mb-5 mb-md-0">
            <div className="feature-box flex-column flex-xl-row align-items-center align-items-lg-start text-center text-lg-start">
              <div className="feature-box-icon bg-transparent mb-4 mb-xl-0 p-0">
                <i className="icons icon-phone text-9 text-color-light position-relative top-4"></i>
              </div>
              <div className="feature-box-info line-height-1 ps-2">
                <span className="d-block font-weight-bold text-color-light text-5 pb-1 mb-1">
                  Call Us Now
                </span>
                <a
                  href="tel:+919958799582"
                  className="text-color-light text-4 line-height-7 text-decoration-none"
                >
                  +91 9958799582
                </a>
              </div>
            </div>
          </div>
          {/* 24/7 Assistance */}
          <div className="col-md-3 mb-5 mb-md-0">
            <div className="feature-box flex-column flex-xl-row align-items-center align-items-lg-start text-center text-lg-start">
              <div className="feature-box-icon bg-transparent mb-4 mb-xl-0 p-0">
                <img
                  width="45"
                  src={support}
                  alt="24x7 support"
                  data-icon
                  data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light position-relative bottom-3'}"
                />
              </div>
              <div className="feature-box-info line-height-1 ps-xl-3">
                <span className="d-block font-weight-bold text-color-light text-5 pb-1 mb-1">
                  24/7 Assistance
                </span>
                <a
                  href="tel:+919958799582"
                  className="text-color-light text-4 line-height-7 text-decoration-none"
                >
                  +91 9958799582
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-5 mb-md-0">
            <div className="feature-box flex-column flex-xl-row align-items-center align-items-lg-start text-center text-lg-start">
              <div className="feature-box-icon bg-transparent mb-4 mb-xl-0 p-0">
                <img
                  width="45"
                  src={officeTime}
                  alt="24x7 support"
                  data-icon
                  data-plugin-options="{'onlySVG': true, 'extraClass': 'svg-fill-color-light position-relative bottom-3'}"
                />
              </div>

              <div className="feature-box-info line-height-1 ps-xl-3">
                <span className="d-block font-weight-bold text-color-light text-5 pb-1 mb-1">
                  Opening Hours
                </span>
                <div className="feature-box-info line-height-1 ps-2">
                  <p
                    className="text-color-light text-4 line-height-4 font-weight-light mb-0"
                    style={{
                      position: "relative",
                      right: "6px",
                    }}
                  >
                    Mon - Sat: 10:00 am to <br />
                    07:00 pm | Sunday : Closed
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr className="bg-light opacity-2 my-0" />
      <div className="container pb-5">
        <div className="row text-center text-md-start py-4 my-5">
          {/* Logo */}
          <div className="col-md-3 align-self-center text-center text-md-start text-lg-center mb-5 mb-lg-0 position-relative">
            <NavLink
              to="/"
              className="text-decoration-none position-relative d-inline-block logo-container"
            >
              <img
                src={logo}
                className="img-fluid footer-logo-mobile"
                style={{width: "500px"}}
                alt="Logo"
              />
              <img
                src={hoverImage}
                className="img-fluid hover-image position-absolute"
                alt="Hover Image"
              />
            </NavLink>
          </div>

          {/* About Us */}
          <div className="col-md-3 mb-5 mb-lg-0">
            <h5 className="text-transform-none font-weight-bold text-color-light text-4-5 mb-4">
              About Us
            </h5>
            <ul className="list list-unstyled">
              <li className="pb-1 mb-2">
                <span className="d-block font-weight-semibold line-height-1 text-color-grey text-3-5">
                  ADDRESS
                </span>
                <a
                  role="nutton"
                  href="https://www.google.com/maps?q=28.704134, 77.099506" // Direct Google Maps URL with location
                  className="text-color-light custom-text-underline-1 font-weight-medium text-3-5"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GET DIRECTIONS
                </a>
              </li>
              <li className="pb-1 mb-2">
                <span className="d-block font-weight-semibold line-height-1 text-color-grey text-3-5 mb-1">
                  PHONE
                </span>
                <ul className="list list-unstyled font-weight-light text-3-5 mb-0">
                  <li className="text-color-light line-height-3 mb-0">
                  
                    <a
                      href="tel:+919958799582"
                      className="text-decoration-none text-color-light text-color-hover-default"
                    >
                      +91 9958799582
                    </a>
                  </li>
                </ul>
              </li>
              <li className="pb-1 mb-2">
                <span className="d-block font-weight-semibold line-height-1 text-color-grey text-3-5">
                  EMAIL
                </span>
                <a
                  href="mailto:info@example.com"
                  className="text-decoration-none font-weight-light text-3-5 text-color-light text-color-hover-default"
                >
                  support@findpath.co.in
                </a>
              </li>
            </ul>
            {/* Social Icons */}
            <ul className="social-icons social-icons-medium">
              <li className="social-icons-instagram">
                <a
                  role="button"
                  href="https://www.instagram.com/findpath.co.in/"
                  target="_black"
                  className=" instagram-hover"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li className="social-icons-twitter mx-2">
                <a
                  className="twitter-hover"
                  role="button"
                  href="https://x.com/i/flow/login?redirect_after_login=%2Ffindpathgps"
                  target="_blank"
                  title="Twitter"
                >
                  <i className="fab fa-x-twitter"></i>
                </a>
              </li>
              <li className="social-icons-facebook">
                <a
                  role="button"
                  href="https://www.facebook.com/people/Findpath/61557578795914/"
                  className="no-footer-css"
                  target="_blank"
                  title="Facebook"
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li className="social-icons-twitter mx-2">
                <a
                  role="button"
                  href="https://www.youtube.com/channel/UCMXaS-xGApsGs4DCWRxEO5g"
                  target="_blank"
                  className=" youtube-hover"
                >
                  <i className="fab fa-youtube"></i>
                </a>
              </li>
              <li className="social-icons-linkedin">
                <a
                  role="button"
                  href="https://www.linkedin.com/company/findpath-co-in/"
                  target="_blank"
                  title="Linkedin"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
          </div>

          {/* Quick Links 1 */}
          <div className="col-md-2 mb-5 mb-md-0">
            <h5 className="text-transform-none font-weight-bold text-color-light text-4-5 mb-4">
              Quick Links
            </h5>

            <ul className="list list-unstyled mb-0 font-weight-bold text-color-light text-3-4">
              <li className="mb-2">
                <a role="button" onClick={() => navigate("/blog")}>
                  Blog
                </a>
              </li>
              <li className="mb-2">
                <a role="button" onClick={() => navigate("/")}>
                  Home
                </a>
              </li>
              <li className="mb-2">
                <a
                  className=""
                  role="button"
                  onClick={() => navigate("/aboutus")}
                >
                  About us
                </a>
              </li>
              <li className="mb-2">
                <a
                  className=""
                  role="button"
                  onClick={() => navigate("/contactus")}
                >
                  Contact us
                </a>
              </li>
              <li className="mb-2">
                <a
                  className=""
                  role="button"
                  onClick={() => navigate("/ourcompany")}
                >
                  Our Company
                </a>
              </li>
            </ul>
          </div>

          {/* Quick Links 2 */}
          <div className="col-md-2 mb-5 mb-md-0">
            <h5 className="text-transform-none font-weight-bold text-color-light text-4-5 mb-4">
              Policies
            </h5>
            <ul className="list list-unstyled mb-0 font-weight-bold text-color-light text-3-4">
              <li className="mb-2">
                <NavLink to="/termsandconditions">T & C Policy</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/returnpolicy">Return Policy</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/warranty-policy">White Papers</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/privacypolicy">Privacy Policy</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/warranty-policy">Warranty Policy</NavLink>
              </li>
              <div className="mb-2">
                <a
                  href="https://play.google.com/store/apps/details?id=com.tbtrack.gps"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={appdownload1}
                    alt="Get it on Google Play"
                    className="app-image"
                    style={{ borderRadius: "8px" }}
                  />
                </a>
              </div>
            </ul>
          </div>

          {/* Quick Links 2 */}
          <div className="col-md-2 mb-5 mb-md-0">
            <h5 className="text-transform-none font-weight-bold text-color-light text-4-5 mb-4">
              Help
            </h5>
            <ul className="list list-unstyled mb-0 font-weight-bold text-color-light text-3-4">
              <li className="mb-2">
                <NavLink to="/faqs">FAQs</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/privacypolicy">How to Buy</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/gps-renewal">GPS Renewal</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/gps-activation">GPS Activation</NavLink>
              </li>
              <li className="mb-2">
                <NavLink to="/scopeofwarranty">Technical Support</NavLink>
              </li>
              <div className="mb-2">
                <a
                  href="https://apps.apple.com/us/app/tb-track-vehicle-tracking/id1249657981"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={appdownload2}
                    alt="Download on the App Store"
                    className="app-image"
                    style={{ borderRadius: "8px" }}
                  />
                </a>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer-copyright bg-light py-1">
        <div className="container py-2">
          <div className="row">
            <div className="col">
              <p className="text-center text-3 mb-0">
                Copyright © 2024 SM Surveillance Private Limited. All rights
                reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
