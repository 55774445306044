import React, { useEffect } from "react";
import "./AllProducts.css";
import p1 from "../../Assests/Product/product1.jpg";
import p2 from "../../Assests/Product/product2.jpg";
import p3 from "../../Assests/Product/product3.jpg";
import p4 from "../../Assests/Product/product4.jpg";

const MobileProductPageHeader = () => (
  <section className="page-header page-header-modern page-header-lg " style={{ backgroundColor: "#e3eff9" }}>
    <div className="container ">
      <div className="row">
        <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
          <h1 className="text-color-dark font-weight-bold">Products</h1>
        </div>
        <div className="col-md-4 order-1 order-md-2 align-self-center">
          <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
            <li>
              <a href="/" className="text-color-default text-color-hover-primary text-decoration-none">HOME</a>
            </li>
            <li className="active">All Products</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
);

const Product = ({ imageSrc, productName, category, price, link }) => (
  <div className="col-sm-6 col-lg-4 mb-4 mb-lg-0 mb-3 ">
    <div className="product mb-0" style={{ backgroundColor: "#e3eff9", padding: "10px", borderRadius: "5px" }}>
      <div className="product-thumb-info border-0 mb-3">
        <div className="product-thumb-info-badges-wrapper">
          <span className="badge badge-ecommerce text-bg-danger">50% OFF</span>
        </div>
        <div className="addtocart-btn-wrapper">
          <a href="shop-cart.html" className="text-decoration-none addtocart-btn" title="Add to Cart">
            <i className="icons icon-bag"></i>
          </a>
        </div>
        <a href={link}>
          <div className="product-thumb-info-image bg-light">
            <img alt="" className="img-fluid" src={imageSrc} />
          </div>
        </a>
      </div>
      <div className="d-flex justify-content-between">
        <div>
          <a href="#" className="d-block text-uppercase text-decoration-none text-color-default text-color-hover-primary line-height-1 text-0 mb-1">
            {category}
          </a>
          <h3 className="text-3-5 font-weight-medium font-alternative text-transform-none line-height-3 mb-0">
            <a href={link} className="text-color-dark text-color-hover-primary">{productName}</a>
          </h3>
        </div>
      </div>
      <div title="Rated 5 out of 5">
        <input type="text" className="d-none" value="5" title="" data-plugin-star-rating="" data-plugin-options="{'displayOnly': true, 'color': 'default', 'size':'xs'}" />
      </div>
      <p className="price text-5 mb-3">
        <span className="sale text-color-dark font-weight-medium">{price.sale}</span>
        <span className="amount">{price.amount}</span>
      </p>
    </div>
  </div>
);

const RelatedProduct = () => {
  return (
    <div className="container d-flex justify-content-center">
      <hr className="solid my-5" />
      <div className="related-products">
        <div className="owl-carousel">
          {/* <!-- Add your product items here --> */}
        </div>
        <div className="owl-nav">
          <button type="button" role="presentation" className="owl-prev">
            <i className="fas fa-chevron-left"></i>
          </button>
          <button type="button" role="presentation" className="owl-next">
            <i className="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
      <div className="products row">
        <div className="col w-100px">
          <div className="products row row-gutter-sm mb-4" style={{ animationDelay: "750ms" }}>
            <Product
              imageSrc={p2}
              productName="Findpath Lite"
              category="Findpath Lite"
              price={{ sale: "₹2499", amount: "₹4,998" }}
              link="/product/findpath-lite" // Add your product link here
            />
            <Product
              imageSrc={p1}
              productName="Findpath Pro"
              category="Findpath Pro"
              price={{ sale: "₹3,499", amount: "₹5,998" }}
              link="/products/findpath-pro"
            />
            <Product
              imageSrc={p3}
              productName="Findpath Go"
              category="Findpath Go"
              price={{ sale: "₹2,999", amount: "₹5,998" }}
              link="/products/findpath-go"
            />
          </div>
          <div className="products row row-gutter-sm mb-4" style={{ animationDelay: "750ms" }}>
            <Product
              imageSrc={p4}
              productName="Findpath Go Plus"
              category="Findpath Go Plus"
              price={{ sale: "₹4,999", amount: "₹11,998" }}
              link="/products/findpath-go-plus"
            />
            <Product
              imageSrc={p2}
              productName="Findpath E-Lock"
              category="Findpath E-Lock"
              price={{ sale: "49,00", amount: "59,00" }}
              link="/products/findpath-e-lock"
            />
            <Product
              imageSrc={p1}
              productName="Findpath Dashcams"
              category="Findpath Dashcams"
              price={{ sale: "49,00", amount: "59,00" }}
              link="/products/findpath-dashcams"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileProductPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <MobileProductPageHeader />
      <div className="container">
        <hr className="solid my-5" />
        <div className="related-industries text-center mb-5">
          <h2 className="font-weight-bold">All Products</h2>
          <p className="text-muted">Explore all products</p>
        </div>
      </div>
      <RelatedProduct />
    </div>
  );
};

export default MobileProductPage;
