import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const SearchForm = () => {
  return (
    <form action="https://www.okler.net/previews/porto/10.2.0/page-search-results.html" method="get">
      <div className="input-group mb-3 pb-1">
        <input className="form-control text-1" placeholder="Search..." name="s" id="s" type="text" />
        <button type="submit" className="btn btn-dark text-1 p-2">
          <i className="fas fa-search m-2"></i>
        </button>
      </div>
    </form>
  );
};

const Categories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch('http://localhost:8000/product-page/left-side-bar/fetchleftsidebar.php') // Update with the actual endpoint
      .then(response => response.json())
      .then(data => {
        setCategories(data.categories); // Ensure the backend sends the 'categories' array
      })
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  return (
    <>
      <h5 className="font-weight-semi-bold pt-3">Categories</h5>
      <ul className="nav nav-list flex-column">
        {categories.map(category => (
          <li className="nav-item" key={category.name}>
            <Link className="nav-link" to={category.link}>{category.name}</Link>
          </li>
        ))}
      </ul>
    </>
  );
};

const Tags = () => {
  const [tags, setTags] = useState([]);

  useEffect(() => {
    fetch('http://localhost:8000/product-page/left-side-bar/fetchleftsidebar.php') // Update with the actual endpoint
      .then(response => response.json())
      .then(data => {
        setTags(data.tags.map(tag => tag.name)); // Ensure the backend sends the 'tags' array
      })
      .catch(error => console.error('Error fetching tags:', error));
  }, []);

  return (
    <>
      <h5 className="font-weight-semi-bold pt-5">Tags</h5>
      <div className="mb-3 pb-1">
        {tags.map(tag => (
          <a href="#" key={tag}>
            <span className="badge badge-dark badge-sm rounded-pill text-uppercase px-2 py-1 me-1">{tag}</span>
          </a>
        ))}
      </div>
    </>
  );
};

const Sidebar = () => {
  return (
    <aside className="sidebar">
      <SearchForm />
      <Categories />
      <Tags />
    </aside>
  );
};

export default Sidebar;
