import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import "./Industry.css";
import GenericImage1 from "../../img/generic/generic-corporate-3-1-full.jpg";
import GenericImage2 from "../../img/generic/generic-corporate-3-2-full.jpg";
import GenericImage3 from "../../img/generic/generic-corporate-3-3-full.jpg";
import BuildBg from "../../img/landing/build_bg.jpg"; // Import the background image
import historyImage1 from "../..//img/history/history-1.jpg";
import historyImage2 from "../../img/history/history-2.jpg";
import historyImage3 from "../../img/history/history-3.jpg";
import parallaxImage from "../../img/parallax/parallax-2.jpg";

const Fmcg = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div>
      <section
        className="page-header page-header-modern page-header-lg"
        style={{ backgroundColor: "#e3eff9" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-8 order-2 order-md-1 align-self-center p-static">
              <h1 className="text-color-dark font-weight-bold">
                Waste Management
              </h1>
            </div>
            <div className="col-md-4 order-1 order-md-2 align-self-center">
              <ul className="breadcrumb d-flex justify-content-md-end text-3-5">
                <li>
                  <a
                    role="button"
                    onClick={() => navigate("/")}
                    className="text-color-default text-color-hover-primary text-decoration-none"
                  >
                    HOME
                  </a>
                </li>
                <li>Industries</li>
                <li className="active">Waste Management</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="container pb-1 ">
        <div className="row pt-4">
          <div className="col">
            <div className="overflow-hidden mb-3">
              <h2
                className="word-rotator slide font-weight-bold text-8 mb-0"
                data-aos="maskUp"
              >
                <span>
                  Transform Waste Management with Smart, Sustainable Solutions
                  Powered by FindPath GPS
                </span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-lg-12">
            <div className="overflow-hidden">
              <p className=" mb-0" data-aos="maskUp" data-aos-delay="250">
                FindPath GPS offers innovative solutions that empower waste
                management companies to optimize their processes and reduce
                environmental impact. With our advanced GPS tracking technology,
                businesses can monitor waste collection routes in real-time,
                ensuring timely pickups and minimizing operational costs.
              </p>
              <p className=" mb-0" data-aos="maskUp" data-aos-delay="250">
                Our solutions provide valuable insights into vehicle performance
                and waste volume, allowing managers to make informed decisions
                about resource allocation and route optimization. Additionally,
                geofencing features enable alerts for unauthorized dumping or
                service deviations, enhancing security and accountability.
              </p>
              <p className=" mb-0" data-aos="maskUp" data-aos-delay="250">
                By leveraging data analytics, waste management companies can
                identify patterns and trends, leading to more efficient
                operations and improved customer service. FindPath GPS empowers
                organizations to streamline their waste management processes,
                reduce their carbon footprint, and contribute to a cleaner,
                greener environment. Experience the benefits of smart,
                sustainable waste management solutions with FindPath GPS and
                take the first step toward a more efficient future.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col py-4">
          <hr class="solid custom-hr" />
        </div>
      </div>
      <div className="container-fluid">
        <div className="row align-items-center bg-color-grey">
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0"
              data-plugin-parallax
              data-plugin-options="{'speed': 3, 'horizontalPosition': '100%'}"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${GenericImage1})`,
              }}
            ></section>
          </div>
          <div className="col-lg-6 p-0">
            <section className="section section-no-border h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-left">
                  <div className="overflow-hidden">
                    <h4
                      className="mb-0"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="100"
                    >
                      <a
                        href="#"
                        className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1"
                      >
                        Route Optimization
                      </a>
                    </h4>
                  </div>

                  <p
                    className="text-2 mb-0"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                  >
                    Route optimization is crucial for efficient waste management
                    operations. FindPath GPS solutions utilize advanced
                    algorithms to determine the most effective routes for waste
                    collection vehicles. By analysing real-time traffic data and
                    waste volume, our system reduces fuel consumption and
                    minimizes collection times. This not only enhances
                    operational efficiency but also contributes to cost savings
                    and a lower environmental impact.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="row align-items-center bg-color-grey">
          <div className="col-lg-6 order-2 order-lg-1 p-0">
            <section className="section section-no-border h-100 m-0">
              <div className="row justify-content-end m-0">
                <div className="col-half-section col-half-section-right custom-text-align-right">
                  <div className="overflow-hidden">
                    <h4
                      className="mb-0"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="100"
                    >
                      <a
                        href="#"
                        className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1"
                      >
                        Asset Monitoring
                      </a>
                    </h4>
                  </div>
                  <p
                    className="text-2 mb-0"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                  >
                    Asset monitoring is essential for effective waste management
                    and operational efficiency. With Findpath’s GPS tracking
                    solutions, businesses can continuously track the location
                    and status of their waste collection vehicles and equipment
                    in real time. This visibility allows fleet managers to
                    ensure that assets are used optimally, reduce the risk of
                    theft or misuse, and improve overall service delivery. By
                    providing insights into asset performance and utilization,
                    FindPath enables waste management companies to make informed
                    decisions, streamline operations, and enhance productivity
                    while minimizing downtime and costs.
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 p-0">
            <section
              className="parallax section section-parallax h-100 m-0"
              data-plugin-parallax
              data-plugin-options="{'speed': 1, 'horizontalPosition': '100%'}"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${GenericImage2})`,
              }}
            ></section>
          </div>
        </div>
        <div className="row align-items-center bg-color-grey">
          <div className="col-lg-6 p-0">
            <section
              className="parallax section section-parallax custom-parallax-bg-pos-left custom-sec-left h-100 m-0"
              data-plugin-parallax
              data-plugin-options="{'speed': 1, 'horizontalPosition': '100%'}"
              style={{
                minHeight: "315px",
                backgroundImage: `url(${GenericImage3})`,
              }}
            ></section>
          </div>
          <div className="col-lg-6 p-0">
            <section className="section section-no-border h-100 m-0">
              <div className="row m-0">
                <div className="col-half-section col-half-section-left">
                  <div className="overflow-hidden">
                    <h4
                      className="mb-0"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="100"
                    >
                      <a
                        href="#"
                        className="text-4 font-weight-bold pt-2 d-block text-dark text-decoration-none pb-1"
                      >
                        Waste Collection
                      </a>
                    </h4>
                  </div>

                  <p
                    className="text-2 mb-0"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="500"
                  >
                    Effective waste collection is vital for maintaining clean
                    and sustainable environments. Findpath’s GPS solutions
                    streamline the waste collection process by providing
                    real-time tracking and route optimization. This ensures
                    timely pickups and efficient service delivery, reducing
                    operational costs and minimizing environmental impact. With
                    detailed analytics and monitoring, waste management
                    companies can identify patterns, enhance resource
                    allocation, and improve overall service quality. By
                    leveraging technology, FindPath helps organizations enhance
                    their waste collection operations, ensuring that communities
                    remain clean and waste is managed responsibly.
                  </p>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <section
        className="parallax section section-text-light section-parallax"
        data-plugin-parallax=""
        data-plugin-options={{ speed: "1.5", parallaxHeight: "210%" }}
        style={{ position: "relative", overflow: "hidden" }}
      >
        {/* Parallax background with inline style */}
        <div
          className="parallax-background"
          style={{
            backgroundImage: `url(${parallaxImage})`,
            backgroundSize: "cover",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "210%",
            transform: "translate3d(0px, -100.171px, 0px)",
            backgroundPositionX: "50%",
          }}
        ></div>

        <section className="call-to-action">
          <div className="container">
            <div className="row">
              <div className="col-sm-9 col-lg-9">
                <div className="call-to-action-content">
                  <h3>
                    Revolutionize Your
                    <strong className="font-weight-extra-bold">
                      {" "}
                      Waste Management
                    </strong>{" "}
                    with{" "}
                    <strong className="font-weight-extra-bold">
                      FindPath GPS Solutions!
                    </strong>
                  </h3>
                  <p className="mb-0">
                    Order Now for Efficient Waste Management Solutions with
                    FindPath GPS
                  </p>
                </div>
              </div>
              <div className="col-sm-3 col-lg-3">
                <div className="call-to-action-btn">
                  <a
                    href="#"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-modern text-2 btn-primary"
                  >
                    Buy Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <div className="row">
        <div className="col-md-8 mx-md-auto text-center">
          <h2 className="text-color-dark font-weight-normal text-7 mb-0 pt-2">
            <strong className="font-weight-extra-bold">What we offer</strong>
          </h2>
          <p>
            Innovative Solutions for Efficient Waste Management with FindPath
            GPS
          </p>

          <section className="timeline" id="timeline">
            <div className="timeline-body">
              <div className="timeline-date">
                <h3 className="text-primary font-weight-bold">2024</h3>
              </div>

              <article
                className="timeline-box left text-start"
                data-aos="fade-in" // AOS animation
                data-aos-delay="200" // Delay for the animation
              >
                <div className="timeline-box-arrow"></div>
                <div className="p-2">
                  <img alt="" className="img-fluid" src={historyImage3} />
                  <h3 className="font-weight-bold text-3 mt-3 mb-1">
                    Real-Time Tracking
                  </h3>
                  <p className="mb-0 text-2">
                    Real-time tracking is essential for efficient waste
                    management. Findpath’s GPS solutions provide continuous
                    visibility into waste collection vehicles, enabling fleet
                    managers to monitor operations effectively.
                  </p>
                </div>
              </article>

              <div className="timeline-date">
                <h3 className="text-primary font-weight-bold">2012</h3>
              </div>

              <article
                className="timeline-box right text-start"
                data-aos="fade-in"
                data-aos-delay="400"
              >
                <div className="timeline-box-arrow"></div>
                <div className="p-2">
                  <img alt="" className="img-fluid" src={historyImage2} />
                  <h3 className="font-weight-bold text-3 mt-3 mb-1">
                    Vehicle Tracking
                  </h3>
                  <p className="mb-0 text-2">
                    Vehicle tracking is vital for effective waste management.
                    Findpath’s GPS solutions provide real-time visibility of
                    waste collection vehicles, enabling fleet managers to
                    optimize routes and monitor performance.
                  </p>
                </div>
              </article>

              <div className="timeline-date">
                <h3 className="text-primary font-weight-bold">2006</h3>
              </div>

              <article
                className="timeline-box left text-start"
                data-aos="fade-in"
                data-aos-delay="600"
              >
                <div className="timeline-box-arrow"></div>
                <div className="p-2">
                  <img alt="" className="img-fluid" src={historyImage1} />
                  <h3 className="font-weight-bold text-3 mt-3 mb-1">
                    Fuel Management
                  </h3>
                  <p className="mb-0 text-2">
                    Fuel management is essential for controlling costs in waste
                    management. Findpath’s GPS solutions provide real-time
                    insights into fuel consumption, helping fleet managers
                    identify inefficiencies such as excessive idling or
                    suboptimal routes.
                  </p>
                </div>
              </article>
            </div>
          </section>
        </div>
      </div>
      <section
        className="section section-dark section-angled border-0 lazyload pb-0 m-0"
        style={{
          backgroundSize: "100%",
          backgroundPosition: "top",
          backgroundImage: `url(${BuildBg})`,
        }}
      >
        <div
          className="section-angled-layer-top section-angled-layer-increase-angle bg-color-grey"
          style={{ padding: "4rem 0" }}
        ></div>
        <div className="container text-center my-5 py-5">
          <h2
            className="font-weight-bold line-height-3 text-12 mt-5 mb-3 appear-animation"
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="250"
            data-appear-animation-duration="750"
            data-aos="fade-up" // Add AOS animation
            data-aos-duration="1000" // Duration of the animation
            data-aos-delay="100" // Delay before the animation starts
          >
            Request Demo
          </h2>
          {/* <h4
            className="font-weight-bold text-9 mb-4 pb-2 appear-animation"
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="500"
            data-appear-animation-duration="750"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
          >
            Purchase now. Only{" "}
            <span
              className="highlighted-word highlighted-word-animation-1 highlighted-word-animation-1-no-rotate highlighted-word-animation-1 highlighted-word-animation-1-light alternative-font-4 font-weight-extra-bold text-4 light appear-animation"
              data-appear-animation="blurIn"
              data-appear-animation-delay="800"
              data-appear-animation-duration="750"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="500"
            >
              $16!
            </span>
          </h4> */}
          <div
            className="appear-animation"
            data-appear-animation="fadeInUpShorter"
            data-appear-animation-delay="900"
            data-appear-animation-duration="750"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="700"
          >
            <h4 className="font-weight-light text-4 col-lg-6 px-0 offset-lg-3 fw-400 mb-5 opacity-8">
              Request a Demo Today and Experience the Future of Waste Management with FindPath GPS
            </h4>
          </div>
          {/* <div className="col-12 px-0 pb-2 mb-4">
            <div className="row flex-column flex-lg-row justify-content-center">
              <div className="col-auto">
                <h5
                  className="font-weight-semibold text-4 positive-ls-2 appear-animation"
                  data-appear-animation="fadeInRightShorter"
                  data-appear-animation-delay="1100"
                  data-appear-animation-duration="750"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="800"
                >
                  <i className="fa fa-check"></i> SUPER HIGH PERFORMANCE
                </h5>
              </div>
              <div className="col-auto mx-5 my-2 my-lg-0">
                <h5
                  className="font-weight-semibold text-4 positive-ls-2 appear-animation"
                  data-appear-animation="fadeInRightShorter"
                  data-appear-animation-delay="1400"
                  data-appear-animation-duration="750"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="900"
                >
                  <i className="fa fa-check"></i> Strict Coding Standards
                </h5>
              </div>
              <div className="col-auto">
                <h5
                  className="font-weight-semibold text-4 positive-ls-2 appear-animation"
                  data-appear-animation="fadeInRightShorter"
                  data-appear-animation-delay="1600"
                  data-appear-animation-duration="750"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                >
                  <i className="fa fa-check"></i> Free Lifetime Updates
                </h5>
              </div>
            </div>
          </div> */}
          <a
            role="button"
            onClick={() => navigate("/getademo")}
            className="btn btn-dark btn-modern btn-rounded px-5 btn-py-3 text-4 appear-animation"
            data-appear-animation="fadeIn"
            data-appear-animation-delay="1800"
            data-appear-animation-duration="750"
            target="_blank"
            rel="noopener noreferrer"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="1200"
          >
            Request Demo Now
          </a>
        </div>
        <div className="row border border-start-0 border-bottom-0 border-end-0 border-color-light-2">
          <div className="col-6 col-md-3 text-center d-flex align-items-center justify-content-center py-4">
            <a
              href="#"
              className="text-decoration-none"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="icon-box"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1400"
              >
                <i className="icon-bg icon-menu-1"></i>
                <h4 className="text-4 mb-0">
                  1 Year Warranty
                  {/* <small className="d-block p-relative bottom-4 opacity-6 ls-0">
                    (SAMPLE SITES)
                  </small> */}
                </h4>
              </div>
            </a>
          </div>
          <div className="col-6 col-md-3 text-center divider-left-border border-color-light-2 d-flex align-items-center justify-content-center py-4">
            <a
              href="#"
              className="text-decoration-none"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="icon-box"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1600"
              >
                <i className="icon-bg icon-menu-2"></i>
                <h4 className="text-4 mb-0">7-Day Replacement</h4>
              </div>
            </a>
          </div>
          <div className="col-6 col-md-3 text-center divider-left-border border-color-light-2 d-flex align-items-center justify-content-center py-4">
            <a
              href="#"
              className="text-decoration-none"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="icon-box"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1800"
              >
                <i className="icon-bg icon-menu-3"></i>
                <h4 className="text-4 mb-0">Free Express Delivery</h4>
              </div>
            </a>
          </div>
          <div className="col-6 col-md-3 text-center divider-left-border border-color-light-2 d-flex align-items-center justify-content-center py-4">
            <a
              href="#"
              className="text-decoration-none"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className="icon-box"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="2000"
              >
                <i className="icon-bg icon-menu-4"></i>
                <h4 className="text-4 mb-0">
                  GST Billing
                  <br />
                  {/* <span className="text-2 d-block pt-1">(coming soon)</span> */}
                </h4>
              </div>
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Fmcg;
